import React, {useEffect, useState} from "react"
import axios from "axios"
import AuthService from '../services/authentication'
import Card from 'react-bootstrap/Card'
import {Link, useHistory} from "react-router-dom"

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import FormControl from 'react-bootstrap/FormControl'
import Alert from 'react-bootstrap/Alert'

import {calcMixTotal, calcMixItemPrice} from '../helpers/Helpers'

function MixtureSummary(props) {

  let auth = AuthService.useAuth()
  const [orderTotal, setOrderTotal] = useState(0)
  const weights = [200,500]
  const [currentWeight, setCurrentWeight] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  let history = useHistory()
  const [showCapacityAlert, setShowCapacityAlert] = useState(false);
  const [showNameAlert, setShowNameAlert] = useState(true);

  const parseLineItems = order => {
    let lineItems = []
    order.sales_order_items.map(sales_order_item => {
      const lineItem = {...sales_order_item.product, quantity: sales_order_item.quantity, price: sales_order_item.practitioner_price, markup: sales_order_item.markup}
      lineItems.push(lineItem)
    })
    return lineItems
  }

  useEffect(() => {
    calcCurrentWeight()
    setTotalPrice(calcMixTotal(props.lineItems))
  }, [props.lineItems])

  useEffect(() => {
    props.name ? setShowNameAlert(false) : setShowNameAlert(true)
  }, [props.name])

  useEffect(() => {
    currentWeight > props.maxWeight ? setShowCapacityAlert(true) : setShowCapacityAlert(false)
  }, [currentWeight, props.maxWeight])

  const calcCurrentWeight = () => {
    let total = 0
    props.lineItems.map(item => {
      const quantity = parseInt(item.quantity)
      total += !isNaN(quantity) ? quantity : 0
    })
    setCurrentWeight(parseInt(total))
  }

  const simplifiedLineItems = () => props.lineItems.map(({id, quantity, markup, use_rrp}) => ({product_id: id, quantity, markup, use_rrp}))

  const saveOrder = () => {
    axios.post(`/api/mixtures/update`, 
      {
        line_items: simplifiedLineItems(),
        max_weight: props.maxWeight,
        id: props.id,
        note: props.note,
        name: props.name
      },
      {
        headers: auth.authHeader(),
      }
    )
    .then(response => {
      history.push('/order')
    })
    .catch(error => {
      console.log(error)
    })
  }

  const renderMarkupForm = lineItem => {
    return (
      <InputGroup className="mt-2" style={lineItem.use_rrp ? {opacity: 0.2, pointerEvents: 'none'} : {}}>
        <Form.Label className="w-100 text-muted mb-1">Markup:</Form.Label>
        <Form.Control name="markup" type="number" style={{maxWidth: '7em'}} placeholder="0" onChange={e => e.target.value >= 0 && e.target.value <= 300 && props.onLineItemChange(lineItem, 'markup', e.target.value)} value={lineItem.markup ?? 0} min={0} step={0.01} />
        <InputGroup.Text>%</InputGroup.Text>
      </InputGroup>
    )
  }

  const handleQuanityChange = (lineItem, quantity, allow_zero=false) => {
    if(quantity <= props.maxWeight) {
      props.onQuantityChange(lineItem, parseInt(quantity), allow_zero)
    }
  }

  const quantityForm = lineItem => {
    return (
      <Form className="d-inline-flex align-items-center">
        <InputGroup>
          <Form.Label className="w-100 text-muted mb-1">Quantity:</Form.Label>
          <FormControl style={{maxWidth: '7em'}} type="number" value={lineItem.quantity || ''} onBlur={e => handleQuanityChange(lineItem, e.target.value, false)} onChange={e => handleQuanityChange(lineItem, e.target.value, true)} />
          <InputGroup.Text>ml</InputGroup.Text> 
          <Button variant="danger" onClick={e => handleQuanityChange(lineItem, 0, false)}>Remove</Button>
        </InputGroup>
      </Form>
    )
  }

  const renderPricingOptions = lineItem => {
    return (
      <Form className="mt-2">
        <Form.Label className="text-muted me-2 mb-0">Pricing:</Form.Label>
        <Form.Check 
          onChange={e => props.onLineItemChange(lineItem, 'use_rrp', !lineItem.use_rrp)}
          type='radio'
          checked={lineItem.use_rrp}
          label='RRP'
        />
        <Form.Check 
          onChange={e => props.onLineItemChange(lineItem, 'use_rrp', !lineItem.use_rrp)}
          type='radio'
          checked={!lineItem.use_rrp}
          label='Markup'
        />
      </Form>
    )
  }

  const renderLineItems = () => {
    return props.lineItems.map((lineItem, i) => {
      return (
      	<ListGroup.Item key={lineItem.id} className="mt-1">
          <Row>
            <Col xs={9}>
              <p className="w-100 fw-bold">{lineItem.name}</p>
              <div>
                {quantityForm(lineItem)}
                {renderPricingOptions(lineItem)}
                {renderMarkupForm(lineItem)}
              </div>
            </Col>
            <Col className="d-flex align-content-end justify-content-end ">
              <p className="fw-bold">${calcMixItemPrice(lineItem).toFixed(2)}</p>
            </Col>
          </Row>
      	</ListGroup.Item>
      )
    })
  }

  const calcOrderTotal = () => {
  	let total = 0
  	props.lineItems.length && props.lineItems.forEach(lineItem => total += (lineItem.price * lineItem.quantity))
    setOrderTotal(total)
  }

  return (
    <Card>
    	<Card.Header>
        <h4 className="mb-0">Formulation Details</h4>
        <Form.Label className="mt-3 text-muted">1. Name your Formula</Form.Label>
        <Form.Control value={props.name} onChange={e => props.onNameChange(e.target.value)} name="name" placeholder="e.g. Sleep Tonic" className="mb-2" />
        <Form.Label className="w-100 mt-3 text-muted">2. Select your bottle size</Form.Label>
        <ButtonGroup>
          {weights.map((weight, i) => {
            return (
              <ToggleButton key={i} onClick={() => props.onMaxWeightChange(weight)} type="radio" style={{opacity: props.maxWeight != weight ? 0.5 : 1 }} value={weight} checked={props.maxWeight == weight} variant='outline-dark' name="radio">{weight}ml</ToggleButton> 
            )
          })}
        </ButtonGroup>
        <ProgressBar max={props.maxWeight} className="my-2">
          {Object.keys(props.lineItems).length != 0 &&
            props.lineItems.map(lineItem => {
              return (
                <OverlayTrigger key={lineItem.id} placement="bottom" delay={{ show: 100, hide: 250 }} overlay={<Tooltip>{lineItem.name} - {lineItem.quantity}ml</Tooltip>}>
                  <ProgressBar isChild={true} max={props.maxWeight} style={{ borderRight: '1px solid #e9ecef'}} now={lineItem.quantity} />
                </OverlayTrigger>
              )
            })
          }
        </ProgressBar>
        <p className="text-muted">{currentWeight}ml/{props.maxWeight}ml</p>
        <Form.Label className="text-muted">3. Choose your ingredients</Form.Label>
      </Card.Header>
  		<ListGroup variant="flush">
        {Object.keys(props.lineItems).length != 0 && renderLineItems()}
        <ListGroup.Item key='dispensing-charge' className="mt-1">
           <Row>
             <Col xs={9}>
               <p className="w-100 mb-0">Dispensing Charge</p>
             </Col>
             <Col className="d-flex align-content-end justify-content-end ">
               <p className="mb-0">$9.77</p>
             </Col>
           </Row>
        </ListGroup.Item>
  		</ListGroup>
  		<Card.Body>
        <Row>
          <Col xs={12}>
            <Form.Label className="text-muted">4. Patient instructions</Form.Label>
            <Form.Control value={props.note} onChange={e => props.onNoteChange(e.target.value)} name="notes" as="textarea" placeholder="Dosage Notes.." className="mb-2" />
          </Col>
          <Col xs={12} className="mt-4">
            {totalPrice > 0 &&
              <>
                <h4>Patient Total: ${totalPrice.toFixed(2)}*</h4>
                <p>*GST Inclusive</p>
              </> 
            }
          </Col>
          <Col xs={12}>
            {showCapacityAlert &&
              <Alert variant="danger">
                <p className="mb-0">
                  Capacity exceeded. Please remove {currentWeight - props.maxWeight}ml before saving.
                </p>
              </Alert>
            }
            {showNameAlert &&
              <Alert variant="danger">
                <p className="mb-0">
                  Please set a name for this formulation before saving.
                </p>
              </Alert>
            }
            <Button variant="primary" className="mt-2" onClick={saveOrder} disabled={Object.keys(props.lineItems).length == 0 || currentWeight > props.maxWeight || !props.name}>Save</Button>
          </Col>
        </Row>
  		</Card.Body>
    </Card>
  )
}

export default MixtureSummary
