import React, {useEffect, useState} from "react"
import {useParams, useHistory} from "react-router-dom"
import axios from "axios"
import AuthService from '../services/authentication'

import ProductSearchIndex from "../products/ProductSearchIndex"
import MixtureSummary from "../mixtures/MixtureSummary"

import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

function MixtureIndex(props) {

	let auth = AuthService.useAuth()
	let { id } = useParams()
	const [maxWeight, setMaxWeight] = useState(200)
	const [note, setNote] = useState('')
	const [name, setName] = useState('')
	const [defaultMarkup, setDefaultMarkup] = useState(0)
	let [displayMaxProductsPopup, setDisplayMaxProductsPopup] = useState(false)
	let history = useHistory()


	const [lineItems, setlineItems] = useState([]) 
	const [orderId, setOrderId] = useState(0)
	const [currentOrderSent, setCurrentOrderSent] = useState(false)

	const handleLineItemChange = (item, key, value) => {
	  let index = lineItems.indexOf(item)
	  let lineItemsCopy = [...lineItems]
	  let lineItem = {...lineItemsCopy[index]}
	  lineItem[`${key}`] = value
	  lineItemsCopy[index] = lineItem
	  setlineItems(lineItemsCopy)
	}

	const handleProductQuantityChange = (item, quantity, allow_zero = false) => {
	  let index = lineItems.indexOf(item)
	  let lineItemsCopy = [...lineItems]
	  let lineItem = {...lineItemsCopy[index]}
	  if ((quantity != 0 && !isNaN(quantity)) || allow_zero) {
	    lineItem.quantity = quantity
	    lineItemsCopy[index] = lineItem
	  } else {
	    lineItemsCopy.splice(index, 1)
	  }
	  setlineItems(lineItemsCopy)
	}

	const addProductToOrder = item => {
	  item.new_mixture = item.mixture == true
	  item.quantity = item.for_mixture ? 10 : 1 //10mls default mixture addition
	  setlineItems([...lineItems, item])
	}

	const handleFetchOrder = props => {
		console.log(props)
	  props.orderId && setOrderId(props.orderId)
	  props.lineItems && setlineItems(props.lineItems)
	  props.patientEmail && setCustomer({email: props.patientEmail})
	  props.isSent ? setCurrentOrderSent(true) : setCurrentOrderSent(false)
	}

	const handleOrderAddition = item => {
	  let duplicateItem = lineItems.find(lineItem => lineItem.id === item.id && !lineItem.mixture)
	  if (duplicateItem) {
	    const additional_quantity = duplicateItem.for_mixture ? 10 : 1
	    handleProductQuantityChange(duplicateItem, duplicateItem.quantity + additional_quantity)
	  } else {
	    addProductToOrder(item)
	  }
	}

	const parseLineItems = mixture => {
	  let lineItems = []
	  mixture.sales_order_items.map(sales_order_item => {
	    const lineItem = {...sales_order_item.product, quantity: sales_order_item.quantity, price: sales_order_item.practitioner_price, markup: sales_order_item.markup, use_rrp: sales_order_item.use_rrp}
	    lineItems.push(lineItem)
	  })
	  return lineItems
	}

	const fetchMarkup = () => {
		axios.get('/api/practitioner/markup', {headers: auth.authHeader()})
		.then(response => {
		  setDefaultMarkup(parseInt(response.data))
		})
		.catch(error => {
		  console.log(error)
		})
	}

	const fetchMixture = () => {
		axios.get(`/api/mixtures/${id}`, {headers: auth.authHeader()})
		.then(response => {
			console.log(response)
			if(response.data) {
			  const order = {
			    patientEmail: response.data.patient_email,
			    lineItems: parseLineItems(response.data),
			    orderId: response.data.id
			  }
			  response.data.max_weight && setMaxWeight(response.data.max_weight)
			  response.data.note && setNote(response.data.note)
			  response.data.name && setName(response.data.name)
				handleFetchOrder(order)
			}
		})
		.catch(error => {
			console.log(error)
			history.replace('/')
		})
	}

	useEffect(() => {
		fetchMarkup()
		fetchMixture()
	}, [])

	const onAddToOrder = product => {
		let n = Object.keys(lineItems).length 
		product.markup = defaultMarkup
		n < 10 ? handleOrderAddition(product) : setDisplayMaxProductsPopup(true)
	}

	return (
		<>
		<Row>
		  <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
		    <ProductSearchIndex 
         onOrderAddition={product => onAddToOrder(product)}
         variant="mixture"
		    />
		  </Col>
		  <Col xs={{span: 12, order: 1}} md={{span: 6, order: 2}} className="mb-4">
		    <MixtureSummary
		    	id={id}
		      onQuantityChange={(lineItem, quantity, allow_zero=false) => handleProductQuantityChange(lineItem, quantity, allow_zero)}
		      lineItems={lineItems || []}
		      onLineItemChange={handleLineItemChange}
		      onMaxWeightChange={weight => setMaxWeight(weight)}
		      onNoteChange={note => setNote(note)}
		      onNameChange={name => setName(name)}
		      maxWeight={maxWeight}
		      note={note}
		      name={name}
		    />
		  </Col>
		</Row>
		<Modal show={displayMaxProductsPopup} onHide={() => setDisplayMaxProductsPopup(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Product limit reached</Modal.Title>
      </Modal.Header>
      <Modal.Body>Sorry, you can only add 10 products per mixture.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setDisplayMaxProductsPopup(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
		</>
	)
}


export default MixtureIndex