import React, {useState} from "react"
import axios from "axios"
import AuthService from '../services/authentication'
import {useHistory, Prompt} from "react-router-dom"

import MixtureImage from 'images/mixture_image.png'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Alert from "react-bootstrap/Alert"

import {
  useOrder,
  updateLineItem,
  sendOrderEmail
} from '../data/order'

import {sortHerbsAlphabetically} from '../helpers/Helpers'

function PractitionerOrderDetail(props) {

  let auth = AuthService.useAuth()
  let history = useHistory()
  const {order,isLoading, isError, mutate} = useOrder(auth.authHeader())

  const [noteDrafts, setNoteDrafts] = useState({});
  const [markupDrafts, setMarkupDrafts] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const mixturesValid = () => order.mixture_line_items.some(item => item.mixture && Object.keys(item.herbs).length === 0)

  const handleLineItemChange = async (lineItem, property, value, mixture=false) => {
    const updatedOrder = JSON.parse(JSON.stringify(order));

    const lineItemsKey = mixture ? 'mixture_line_items' : 'product_line_items';

    const lineItemIndex = updatedOrder[lineItemsKey].findIndex(item => item.id === lineItem.id);

    if (lineItemIndex === -1) {
      console.error(`Line item with ID ${lineItem.id} not found`);
      return;
    }

    updatedOrder[lineItemsKey][lineItemIndex][property] = value;

    const options = {
      optimisticData: updatedOrder,
      rollbackOnError(error) {
        return error.name !== 'AbortError'
      },
      populateCache: false,
    }
    mutate(
      updateLineItem({
        line_item_id: lineItem.id,
        mixture: mixture,
        property: property,
        value: value,
        auth_header: auth.authHeader()
      }),
      options
    );
  }

  const quantityForm = lineItem => {
    return (
      <Form className="d-inline-flex align-items-center">
        <InputGroup>
          <Button disabled={lineItem.quantity == 0} variant="outline-secondary" onClick={() => handleLineItemChange(lineItem, 'quantity', lineItem.quantity - 1, lineItem.mixture)}>-</Button>
          <FormControl style={{maxWidth: '3rem', textAlign: 'center'}} value={lineItem.quantity || 0} readOnly />
          <Button disabled={lineItem.quantity == lineItem.maxQuantity} variant="outline-secondary" onClick={() => handleLineItemChange(lineItem, 'quantity', lineItem.quantity + 1, lineItem.mixture)}>+</Button>
        </InputGroup>
      </Form>
    )
  }

  const renderLineItems = () => {
    return [...order.product_line_items, ...order.mixture_line_items].map((lineItem, i) => (
      <Row key={lineItem.id} className="mt-4">
        <Col xs={2} className="d-flex justify-content-center">
          <Image 
            thumbnail 
            src={lineItem.mixture ? MixtureImage : lineItem.image_url} 
            style={{aspectRatio: '1/1', width: '100%', objectFit: 'contain', objectPosition: 'center'}}
          />
        </Col>
        {renderLineItemDetails(lineItem)}
      </Row>
    ));
  };

  const handleDraftNoteChange = (lineItemId, draftNote) => {
    setNoteDrafts({ ...noteDrafts, [lineItemId]: draftNote });
    setUnsavedChanges(true);
  }

  const deleteMixture = mixture => {
    axios.post('/api/mixtures/delete',
      {
        id: mixture.id
      },
      {
        headers: auth.authHeader(),
      }
    ).then(response => {
      mutate()
    })
    .catch(error => {
      alert('Could not delete that mixture. Please reload the page and try again.')
    })
  } 

  const renderProductLineItemDetails = lineItem => {
    return (
      <>
        <Col xs={7}>
          <Row className="d-flex align-content-between h-100">
            <Col xs={12}>
              <h4 className="tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-2">{lineItem.name}</h4>
            </Col>
            <Col xs={12}>
              {quantityForm(lineItem)}
              <Form.Control 
                name="notes" 
                as="textarea" 
                placeholder="Notes.." 
                value={noteDrafts[lineItem.id] !== undefined ? noteDrafts[lineItem.id] : lineItem.note} 
                onChange={(e) => handleDraftNoteChange(lineItem.id, e.target.value)}
                className="my-2" 
              />
              <div className="">
                {renderPricingOptions(lineItem)}
                {renderMarkupForm(lineItem)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={3} className="d-flex align-content-end justify-content-end flex-wrap">
          <h4 className="tw-text-2xl">${(lineItem.patient_price * lineItem.quantity).toFixed(2)}</h4>
        </Col>
      </>
    )
  }

  const renderMixtureLineItemDetails = mixture => {
    return (
      <>
        <Col xs={7}>
          <Row className="d-flex align-content-between h-100">
            <Col xs={12}>
              <h4 className="tw-font-bold tw-tracking-tight tw-text-gray-900 tw-pb-2">{mixture.name ? mixture.name : 'New Mixture'}</h4>
            </Col>
            <Col xs={12}>
              {quantityForm(mixture)}
              {mixture.herbs && sortHerbsAlphabetically(mixture.herbs).map(item => <p className="my-2"><span className="fw-bold">{item.name}</span> {item.quantity}ml</p>)}
              <Form.Control 
                name="notes" 
                as="textarea" 
                placeholder="Notes.." 
                value={noteDrafts[mixture.id] !== undefined ? noteDrafts[mixture.id] : mixture.note} 
                onChange={(e) => handleDraftNoteChange(mixture.id, e.target.value)}
                className="my-2" 
              />
              <div className="my-2">
              <Button 
                onClick={async () => {
                  await saveOrder();
                  history.push(`/order/mixtures/${mixture.id}/edit`);
                }} 
                variant="secondary" 
                className="me-2"
              >
                Blend Herbs
              </Button>
              <Button onClick={() => deleteMixture(mixture)} variant="danger">Remove</Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={3} className="d-flex align-content-end justify-content-end flex-wrap">
          <h4 className="tw-text-2xl">${(mixture.patient_price * mixture.quantity).toFixed(2)}</h4>
        </Col>
      </>
    )
  }

  const renderPricingOptions = lineItem => {
    return (
      <Form >
        <Form.Label className="fw-bold me-2">Pricing:</Form.Label>
        <Form.Check 
          inline
          onChange={() => handleLineItemChange(lineItem, 'use_rrp', !lineItem.use_rrp)}
          type='radio'
          checked={lineItem.use_rrp}
          label='RRP'
        />
        <Form.Check 
          inline
          onChange={() => handleLineItemChange(lineItem, 'use_rrp', !lineItem.use_rrp)}
          type='radio'
          checked={!lineItem.use_rrp}
          label='Markup'
        />
      </Form>
    )
  }

  const renderLineItemDetails = lineItem => {
    if(lineItem.mixture) {
      return renderMixtureLineItemDetails(lineItem)
    } else {
      return renderProductLineItemDetails(lineItem)
    }
  }

  const handleDraftMarkupChange = (lineItemId, draftMarkup) => {
    if (draftMarkup < 0) draftMarkup = 0
    if (draftMarkup >= 300) draftMarkup = 300
    setMarkupDrafts({ ...markupDrafts, [lineItemId]: draftMarkup });
    setUnsavedChanges(true);
  }

  const renderMarkupForm = lineItem => {
    return (
      <Form>
        <InputGroup style={lineItem.use_rrp ? {opacity: 0.2, pointerEvents: 'none'} : {}}>
          <Form.Control
            style={{maxWidth: '10em'}}
            name="markup"
            type="number"
            placeholder="0"
            onChange={(e) => handleDraftMarkupChange(lineItem.id, e.target.value)}
            value={markupDrafts[lineItem.id] !== undefined ? markupDrafts[lineItem.id] : lineItem.markup}
            min={0}
            step={0.01}
          />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
      </Form>
    );
  }

  const processDrafts = async (drafts, attribute) => {
    for (let id in drafts) {
      let lineItem = order.product_line_items.find(item => item.id == id) || order.mixture_line_items.find(item => item.id == id);
      if (lineItem && drafts[id] !== lineItem[attribute]) {
        await handleLineItemChange(lineItem, attribute, drafts[id], lineItem.mixture);
      }
    }
  }

  const saveOrder = async () => {
    await processDrafts(noteDrafts, 'note');
    await processDrafts(markupDrafts, 'markup');
    setUnsavedChanges(false);
  }

  const handleAddMoreProducts = async () => {
    await saveOrder();
    history.push('/order/edit');
  }

  const handleSendOrderEmail = async () => {
    await saveOrder();
    
    sendOrderEmail({order_id: order.id, auth_header: auth.authHeader()})
      .then(response => {
        mutate();
        alert('Prescription has been sent to patient.');
        history.push('/');
      })
      .catch(error => {
        console.log(error);
        alert('Could not send that order. Please reload the page and try again.');
      });
  }

  if(isLoading) return <div>Loading...</div>;
  if(isError) return <div>Error occurred!</div>;

  return (
    <>
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      {(Object.keys(order.product_line_items).length != 0 || Object.keys(order.mixture_line_items).length != 0) && renderLineItems()}
      <p className="text-muted mt-4">All prices include GST.</p>
      <p className="text-muted">This prescription is valid for 6 months or until all products have been purchased.</p>
      <h4 className="fw-bold mt-4">Patient Sub Total: ${order.totals.patient_total.toFixed(2)}</h4>
      {unsavedChanges && <Button onClick={saveOrder} className="mx-2 mt-2">Save</Button>}
      <Button onClick={handleAddMoreProducts} variant="secondary" className="me-2 mt-2">Add more products</Button> 
      <Button 
          variant="primary" 
          className="mt-2" 
          onClick={handleSendOrderEmail} 
          disabled={
            (order.product_line_items.length === 0 && order.mixture_line_items.length === 0) || 
            mixturesValid()
          }
      >
        Send to Patient
      </Button>
      {mixturesValid() &&
        <Alert variant="danger"  className="mt-2">
          <p className="mb-0">
            One of your custom herbal formulations is incomplete. Please finish blending herbs or remove it from the order before sending to your patient.
          </p>
        </Alert>
      }
    </>
  )
}

export default PractitionerOrderDetail
