import React, { useState } from "react"
import { usePatientOrder } from '../data/patientOrder'
import EwaySecureFields from "./EwaySecureFields";
import AddressFields from "./AddressFields";
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useHistory } from "react-router-dom"
import { gtmTriggerEvent } from "../data/gtm";
import { InputField } from "../components/input";
import Popup from "./Popup";

const Spinner = () => {
  return (
    <svg className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}

export default function CheckoutForm({ token }) {
  const { patientOrder, isLoading } = usePatientOrder(token)
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);
  const [secureFieldCode, setSecureFieldCode] = useState(undefined)
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [error, setError] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const history = useHistory()


  const [validationErrors, setValidationErrors] = useState({
    shippingAddress: {
      address: false,
      suburb: false,
      city: false,
      business: false,
      postalCode: false
    },
    billingAddress: {}
  });

  const [shippingAddress, setShippingAddress] = useState({
    address: '',
    suburb: '',
    city: '',
    business: '',
    postalCode: ''
  });

  const [billingAddress, setBillingAddress] = useState({
    suburb: '',
    city: '',
    business: '',
    postalCode: ''
  });

  const [cardErrors, setCardErrors] = useState({
    cardHolderName: false,
    cardCVN: false,
    cardNumber: false,
    cardExpiry: false
  });

  const validateForm = () => {
    const newErrors = {
      shippingAddress: {
        address: !shippingAddress.address,
        city: !shippingAddress.city,
        postalCode: !shippingAddress.postalCode
      },
      billingAddress: {}
    };

    setValidationErrors(newErrors);
    console.log(validationErrors)

    return Object.values(newErrors.shippingAddress).some(Boolean) ||
      Object.values(newErrors.billingAddress).some(Boolean);
  };

  const handleCardErrors = (errors) => {
    const containsError = (codes) => errors.some(error => codes.includes(error));

    setCardErrors({
      cardName: containsError(['V6021', 'V6100']),
      cardCVN: containsError(['V6023', 'V6106']),
      cardNumber: containsError(['V6110', 'V6022', 'V6010']),
      cardExpiry: containsError(['V6033', 'V6101', 'V6102'])
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setError(false)

    const hasErrors = validateForm();
    if (hasErrors) {
      setError(true)
      setPopupMessage('There is an issue with your address. Ensure you select an address from the dropdown and try again.');
      setPopupOpen(true);
      setIsProcessing(false);
      return;
    }

    const requestBody = {
      secureFieldCode: secureFieldCode,
      token: token,
      shippingAddress: shippingAddress,
      billingAddress: billingAddress
    };

    try {
      const response = await fetch('/api/orders/create-eway-transaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json(); 

      if (!response.ok) {
        setError(true);
        setPopupMessage('Please check your card details and try again.');
        setPopupOpen(true);
        console.log(responseData)
        if (responseData.eway_errors) {
          handleCardErrors(responseData.eway_errors);
        } else if (responseData.response_code) {
          handleCardErrors([responseData.response_code]);
        } else {
          // Handle other types of errors
        }
      } else {
        setPaymentSuccess(true)
        setPopupMessage('Thanks for your purchase!');
        setPopupOpen(true);
        gtmTriggerPurchase(responseData.transaction_id)
        setTimeout(() => history.replace(`/transaction/${responseData.uuid}`), 1000)
      }
    } catch (error) {
      setError(true);
      setPopupMessage('Your payment could not be processed. Please try again.');
      setPopupOpen(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const gtmTriggerPurchase = transaction_id => {
    const shipping = parseFloat(patientOrder?.shipping.rate)
    const sub_total = parseFloat(patientOrder?.sub_total)
    const tax = parseFloat(patientOrder?.gst)

    const items = patientOrder.line_items.map(lineItem => ({
      item_name: lineItem.name,
      price: lineItem.patient_price,
      quantity: lineItem.ordering_quantity
    }));

    gtmTriggerEvent('purchase', {
      currency: "NZD",
      value: sub_total,
      shipping: shipping,
      tax: tax,
      transaction_id: transaction_id,
      items: items
    })
  }

  return (
    <section className="tw-flex-auto tw-pb-16 tw-pt-12 sm:tw-pt-16 lg:tw-pb-24 lg:tw-pt-0">
      <form onSubmit={handleSubmit}>
        <div className="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-4">
          <InputField
            disabled
            label="Email Address"
            type="email"
            id="email-address"
            name="email-address"
            autoComplete="email"
            value={patientOrder?.patient.email}
            isLoading={!patientOrder?.patient.email}
          />

          <AddressFields 
            label="Shipping" 
            setAddress={setShippingAddress} 
            address={shippingAddress} 
            errors={validationErrors.shippingAddress} 
            setErrors={newErrors => setValidationErrors(prev => ({ ...prev, shippingAddress: newErrors }))}
          />

          {!isBillingSameAsShipping && 
            <AddressFields 
              label="Billing" 
              setAddress={setBillingAddress} 
              address={billingAddress} 
              errors={validationErrors.billingAddress} 
              setErrors={newErrors => setValidationErrors(prev => ({ ...prev, billingAddress: newErrors }))}
            />
          }

          <div className={`tw-col-span-full tw-flex tw-space-x-2 tw-mb-3 ${isLoading ? 'tw-invisible' : ''}`}>
            <div className="tw-flex tw-h-5 tw-items-center">
              <input
                id="same-as-shipping"
                name="same-as-shipping"
                type="checkbox"
                onChange={() => setIsBillingSameAsShipping(!isBillingSameAsShipping)}
                defaultChecked={isBillingSameAsShipping}
                className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-gray-400 focus:tw-ring-0"
              />
            </div>
            <label htmlFor="same-as-shipping" className="tw-text-sm tw-font-medium tw-text-gray-700">
              Billing same as shipping address
            </label>
          </div>

          <EwaySecureFields setSecureFieldCode={setSecureFieldCode} cardErrors={cardErrors} />
        </div>

        <button
          type="submit"
          disabled={isProcessing || isLoading || paymentSuccess || !patientOrder?.total}
          className={`tw-flex tw-justify-center tw-mt-6 tw-w-full tw-rounded-md tw-border tw-border-transparent tw-bg-integria-green tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white hover:tw-bg-opacity-90 focus:tw-outline-none focus:tw-border-blue-400 disabled:tw-opacity-80 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed`} 
        >
          {paymentSuccess ?
            <><CheckCircleIcon className="tw-h-5 tw-w-5 tw-mr-2" />&nbsp;Success</>
          :
          isProcessing ?
            <><Spinner />&nbsp;Processing</>
          :
          isLoading ?
            <>&nbsp;</>
          :
            patientOrder?.total > 0 ? `Pay $${parseFloat(patientOrder?.total).toFixed(2)}` : 'Pay'
          }
        </button>
      </form>
      <Popup open={popupOpen} setOpen={setPopupOpen} success={paymentSuccess} message={popupMessage} />
    </section>
  )
}
