import React, { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { XMarkIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'

const faqs = [
  {
    question: "Can I return or get a refund for my order?",
    answer: "Please only order what you require, as Integria has a strict no-returns/refunds policy.",
  },
  {
    question: "How long is my prescription valid for?",
    answer: "This prescription is valid for 6 months or until all products have been purchased.",
  },
  {
    question: "Can I order part of my prescription?",
    answer: "You may order all or part of this prescription, and the remaining products can be ordered at a later date (within 6 months of issue).",
  },
  {
    question: "Who should I contact for more information on these products?",
    answer: "For more information on these products, please consult your prescribing health practitioner.",
  },
  {
    question: "When I press the pay button, nothing happens?",
    answer: "This is most likely an address error. Please ensure the address is selected from the drop-down provided and not typed out manually. Please note special characters are not valid in the address lines.",
  },
  {
    question: "Can I ship/Bill to PO Boxes?",
    answer: "No, PO boxes are not accepted in the 'ship to' or 'bill to' address lines on the MyPatient ordering site.",
  },
  {
    question: "If a product is out of stock, will I be notified when it is available again?",
    answer: "Yes, for any out of stock products on your script, we will send you an email to advise when this product is back in stock. If you wish to stop receiving these notifications, please contact your Practitioner to delete the script.",
  },
  {
    question: "What is the freight cost and freight free threshold?",
    answer: "Freight cost is $8.50 + GST unless an order is valued at $200 or more + GST, in which case you will receive free shipping.",
  },
  {
    question: "Will Custom Herbal Tinctures arrive at the same time as the rest of the products ordered?",
    answer: "Custom herbal orders are shipped independently and will not be in the same box as the rest of the items ordered.",
  },
  {
    question: "Who do I contact regarding script issues?",
    answer: "Any issues with the script, including refills and merging multiple scripts, will need to go directly to your practitioner.",
  },
]

export const FaqPanel = () => {
  return (
    <div className="tw-mx-auto tw-max-w-4xl tw-divide-y tw-divide-gray-900/10">
      <h2 className="tw-text-2xl tw-font-bold tw-leading-10 tw-tracking-tight tw-text-gray-900">Frequently asked questions</h2>
      <dl className="tw-mt-10 tw-space-y-6 tw-divide-y tw-divide-gray-900/10">
        {faqs.map((faq) => (
          <Disclosure as="div" key={faq.question} className="tw-pt-6">
            {({ open }) => (
              <>
                <dt>
                  <DisclosureButton className="tw-flex tw-w-full tw-items-start tw-justify-between tw-text-left tw-text-gray-900">
                    <span className="tw-text-base tw-font-semibold tw-leading-7">{faq.question}</span>
                    <span className="tw-ml-6 tw-flex tw-h-7 tw-items-center">
                      {open ? (
                        <MinusIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                      ) : (
                        <PlusIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                      )}
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="tw-mt-2 tw-pr-12">
                  <p className="tw-text-base tw-leading-7 tw-text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>
    </div>  
  )
}

export default function FaqModal() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div onClick={() => setOpen(true)} className='tw-group tw-inline-flex tw-text-sm tw-text-gray-500 hover:tw-text-gray-700 tw-cursor-pointer'>
        <span>
          Frequently asked questions
        </span>
        <QuestionMarkCircleIcon className='tw-ml-2 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400 group-hover:tw-text-gray-500' />
      </div>
      <Dialog className="tw-relative tw-z-10" open={open} onClose={setOpen}>
        <DialogBackdrop
          transition
          className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in"
        /> 
        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <DialogPanel
              transition
              className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all data-[closed]:tw-translate-y-4 data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in sm:tw-my-8 sm:tw-w-full sm:tw-max-w-screen-md sm:tw-p-6 data-[closed]:sm:tw-translate-y-0 data-[closed]:sm:tw-scale-95"
            >
              <div>
                <div className="tw-absolute tw-right-0 tw-top-0 tw-block tw-pr-4 tw-pt-4">
                  <button
                    type="button"
                    className="tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                  </button>
                </div>
                <FaqPanel />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}
