import React, {useState, useEffect, Fragment} from "react"
import { InputLabel, SkeletonInput } from "../components/input";

const secure_field_styles = `
  appearance: none;
  border-radius: 6px;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  display: block;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  tab-size: 4;
  height: 38px;
`;

const createEwayFieldConfig = (fieldType, fieldDivId) => ({
  publicApiKey: process.env.EWAY_API_KEY,
  fieldDivId,
  fieldType,
  styles: secure_field_styles,
  autocomplete: true 
});

const EwayInput = ({id, label, error}) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
	  const fieldElement = document.getElementById(id);
	  if (!fieldElement) return;

	  const observer = new MutationObserver(mutations => {
	    mutations.forEach(mutation => {
	      mutation.addedNodes.forEach(node => {
	        if (node.tagName === 'IFRAME') {
	          node.onload = () => {
	            setIsLoading(false);
	          };
	        }
	      });
	    });
	  });

	  observer.observe(fieldElement, { childList: true });

	  return () => observer.disconnect();
	}, [id]);

  return (
  	<>
	  {isLoading && <SkeletonInput label={label} />}
    <div className={`tw-col-span-full ${isLoading ? 'tw-hidden' : ''}`}>
      <InputLabel htmlFor={id} error={error}>
        {label}
      </InputLabel>
      <div className={`tw-block tw-mt-1 tw-shadow-sm tw-rounded-[6px] ${error ? 'tw-ring-1 tw-ring-red-400' : '' }`}>
        <div id={id} style={{height: '38px'}}></div>
      </div>
    </div>
    </>
  )
}

const nameFieldConfig = createEwayFieldConfig('name', 'eway-secure-field-name');
const cardFieldConfig = createEwayFieldConfig('card', 'eway-secure-field-card');
const expiryFieldConfig = createEwayFieldConfig('expirytext', 'eway-secure-field-expiry');
const cvnFieldConfig = createEwayFieldConfig('cvn', 'eway-secure-field-cvn');

export default function EwaySecureFields({setSecureFieldCode, cardErrors}) {

	const [renderKey, setRenderKey] = useState(Date.now());

	const setupSecureFields = () => {
		window.eWAY.setupSecureField(nameFieldConfig, secureFieldCallback);
		window.eWAY.setupSecureField(cardFieldConfig, secureFieldCallback);
		window.eWAY.setupSecureField(expiryFieldConfig, secureFieldCallback);
		window.eWAY.setupSecureField(cvnFieldConfig, secureFieldCallback);
	};

	const secureFieldCallback = (event) => {
	  if (!event.fieldValid) {
	    console.log(event);
	  } else {
	    console.log(event)
	    setSecureFieldCode(event.secureFieldCode);
	  }
	};

	const triggerRerender = () => {
	  setRenderKey(Date.now());
	};

	useEffect(() => {
		// eWay secure fields need to be regenerated after submission (on error) since secure field code is one time use
	  if (Object.values(cardErrors).includes(true)) {
	    triggerRerender();
	  }
	}, [cardErrors]);

	useEffect(() => {
	  if (window.eWAY) {
	    setupSecureFields();
	  }
	}, [renderKey]); 

	useEffect(() => {
	  const script = document.createElement('script');
	  script.src = "https://secure.ewaypayments.com/scripts/eWAY.min.js";
	  script.setAttribute('data-init', 'false');
	  script.async = true;
	  document.body.appendChild(script);

	  script.onload = () => {
	  	setupSecureFields()
	  };

	  return () => {
	    document.body.removeChild(script);
	  };
	}, []);

	return (
		<Fragment key={renderKey}>
			<div className="tw-col-span-full">
			  <EwayInput id="eway-secure-field-name" error={cardErrors.cardName} label="Name on Card" />
			</div>

			<div className="tw-col-span-full">
			  <EwayInput id="eway-secure-field-card" error={cardErrors.cardNumber} label="Card Number" />
			</div>

			<div className="tw-col-span-8">
			  <EwayInput id="eway-secure-field-expiry" error={cardErrors.cardExpiry} label="Expiration Date" />
			</div>

			<div className="tw-col-span-4">
			  <EwayInput id="eway-secure-field-cvn" error={cardErrors.cardCVN} label="CVN" />
			</div>
		</Fragment>
	)
}







